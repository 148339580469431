import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedin, isDisplayName } = useContext(AuthContext);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedin()) {
          if (
            !isDisplayName() &&
            props.location.pathname !== '/updateProfile'
          ) {
            return <Redirect to='/updateProfile' />;
          } else {
            return <Component {...props} />;
          }
        } else {
          return <Redirect to='/' />;
        }
      }}
    />
  );
};

export default PrivateRoute;
