import React, { useState, useEffect, createContext } from 'react';
import { auth } from '../utils/Firebase';

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  const login = async () => {
    let userCredential = await auth.signInAnonymously();
    setCurrentUser(userCredential.user);
  };

  const updateProfile = async (username) => {
    return auth.currentUser
      .updateProfile({ displayName: username })
      .then(() => {
        setCurrentUser(null);
        setCurrentUser(auth.currentUser);
      });
  };

  const logout = () => {
    auth.signOut();
  };

  const isLoggedin = (t) => {
    return currentUser != null;
  };

  const isDisplayName = (t) => {
    return (
      currentUser != null &&
      currentUser.displayName != null &&
      currentUser.displayName !== ''
    );
  };

  const value = {
    currentUser,
    login,
    logout,
    updateProfile,
    isLoggedin,
    isDisplayName,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
