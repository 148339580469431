import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  palette: {
    type: 'dark',
    background: {
      default: '#000b1e',
      // paper: '#252525',
    },
    primary: {
      main: '#21aee0',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#E07E6B',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1.3rem',
      },
    },
  },
  typography: {
    h6: {
      color: '#ffffff',
    },
  },
});

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    textAlign: 'center',
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0),
  },
  title: {
    flexGrow: 1,
  },
  translation: {
    marginTop: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  AppBar: {
    background: '#0000',
  },
}));
