import { useStyles } from '../../utils/Style';
import { Grid, Typography } from '@material-ui/core';
import logo from '../../assets/logo.png';

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid container justify='center' alignItems='center'>
        <Grid item>
          <img src={logo} alt='Logo' style={{ maxWidth: 50 }} />
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>
            Copyright: &copy; {new Date().getFullYear()}{' '}
            <a
              href='https://sam-techs.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              sam-techs.com
            </a>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
