import { functions, db, auth } from './Firebase';

export default class Api {
  getRandom = () => {
    return functions.httpsCallable('random');
  };

  translate = (trnaslation) => {
    return db.collection('translations').doc(trnaslation.id).update({
      arabic: trnaslation.arabic,
      translator: auth.currentUser.uid,
    });
  };

  getStats = () => {
    return functions.httpsCallable('stats');
  };
}
