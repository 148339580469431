import React, { useEffect, useState, useContext } from 'react';
import Api from '../../utils/Api';

import {
  Typography,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import { useStyles } from '../../utils/Style';
import { AuthContext } from '../../context/AuthContext';
import { Translate, Undo } from '@material-ui/icons';

const TranslationPage = () => {
  const api = new Api();
  //Context
  const { currentUser } = useContext(AuthContext);
  //State
  const [word, setWord] = useState(null);
  const [arabic, setArabic] = useState('');
  const [stats, setStats] = useState('');
  const [loading, setLoading] = useState(false);
  const [arabicError, setArabicError] = useState(null);

  //Style
  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const getRandom = api.getRandom();
      var randomResult = await getRandom();
      setWord(randomResult.data);
      const getStats = api.getStats();
      var statsResult = await getStats();
      setStats(statsResult.data);
    } catch (error) {
      console.log(error);
    } finally {
      setArabic('');
      setArabicError(null);
      setLoading(false);
    }
  };

  const updateTranslation = async () => {
    setLoading(true);
    await api.translate({
      id: word.id,
      arabic,
      translator: currentUser.uid,
    });
    fetchData();
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (arabic && arabic !== '') {
      updateTranslation();
      setArabicError(null);
      setArabic('');
    } else {
      setArabicError('فش ترجمة');
    }
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {word && (
        <div className={classes.translation}>
          <Typography component='h1' variant='h4' align='center'>
            ترجم: {word.hebrew}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='arabic'
              label='بالعربي'
              name='arabic'
              value={arabic}
              required
              autoFocus
              onChange={(e) => setArabic(e.target.value)}
              inputRef={(input) => input && input.focus()}
              autoComplete='off'
              error={arabicError !== null && arabicError !== ''}
              helperText={arabicError}
            ></TextField>
            <Grid container justify='center' spacing={1}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.submit}
                  endIcon={<Translate />}
                >
                  ترجم
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='secondary'
                  className={classes.submit}
                  onClick={fetchData}
                  endIcon={<Undo />}
                >
                  فشق
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography variant='body2' className={classes.title}>
            ترجمنا {stats.translated} من اصل {stats.total} اسم
          </Typography>
          <Typography variant='body2' className={classes.title}>
            {currentUser.displayName} انت ترجمت {stats.userCount}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TranslationPage;
