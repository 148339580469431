import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import logo from '../../assets/logo.png';

const HomePage = () => {
  const { login } = useContext(AuthContext);

  const handleLogin = () => {
    login();
  };

  return (
    <Grid container direction='column' justify='center' spacing={3}>
      <Grid item align='center'>
        <img src={logo} alt='Logo' style={{ maxWidth: 150 }} />
      </Grid>
      <Grid item>
        <Typography variant='body1'>
          عزيزي المترجم...
          <br />
          شكرا على تعاونك ومشاركتك.
          <br />
          هدف التطبيق بشكل اولي هو ترجمت اسماء عائلات عيلبون، في سبيل إنشاء
          قاعدة بيانات لأسماء كل اهالي البلد باللغة العربية.
          <br />
          هاي البيانات رح تكون متاحة بشكل طبيعي لكل انسان بطلبها، لاي سبب كان.
          <br />
          رح نعرضلك اسماء (او مقاطع) من اسماء باللغه العبريه ونطلب منك ترجمتها
          للعربي. في حالة انك مش متأكد من الاسم رجاءً فشق عنه. نجاح المشروع
          يعتمد بشكل اساسي على دقة المعلومات الي بتفوتوها.
          <br />
          أحسن مترجم من ناحية عدد ودقة بطلعله مني ست قناني بيره هديه.
          <br />
          ترجمه سعيده
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={handleLogin}
          fullWidth
          variant='outlined'
          color='primary'
        >
          ابدا
        </Button>
      </Grid>
    </Grid>
  );
};

export default HomePage;
