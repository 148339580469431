import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import TranslationPage from './components/translation/TranslationPage';
import PublicRoute from './routing/PublicRoute';
import PrivateRoute from './routing/PrivateRoute';
import AuthContextProvider from './context/AuthContext';
import Navbar from './components/layout/Navbar';
import { theme } from './utils/Style';

import { ThemeProvider } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import { RTL } from './utils/RTL';
import HomePage from './components/layout/HomePage';
import UpdateProfile from './components/auth/UpdateProfile';
import Footer from './components/layout/Footer';
import { useStyles } from './utils/Style';
const App = () => {
  const classes = useStyles();
  return (
    <RTL>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <div dir='rtl' className={classes.root}>
            <CssBaseline />
            <Navbar></Navbar>
            <Container component='main' className={classes.main} maxWidth='sm'>
              <Router>
                <Switch>
                  <PublicRoute
                    exact
                    restricted={true}
                    path='/'
                    component={HomePage}
                  />
                  <PrivateRoute
                    exact
                    path='/updateProfile'
                    component={UpdateProfile}
                  />
                  <PrivateRoute
                    exact
                    path='/translate'
                    component={TranslationPage}
                  />
                </Switch>
              </Router>
            </Container>
            <Footer></Footer>
          </div>
        </AuthContextProvider>
      </ThemeProvider>
    </RTL>
  );
};

export default App;
