import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useStyles } from '../../utils/Style';

const Navbar = () => {
  const classes = useStyles();
  const { currentUser, logout, isLoggedin, isDisplayName } = useContext(
    AuthContext
  );
  const onLogoutClick = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <AppBar className={classes.AppBar} position='static'>
      {isLoggedin('Navbar') && isDisplayName('Navbar') ? (
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            مرحبا {currentUser.displayName}
          </Typography>
          <Button color='secondary' onClick={onLogoutClick}>
            <Typography variant='h6'>خروج</Typography>
          </Button>
        </Toolbar>
      ) : (
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            مترجم
          </Typography>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Navbar;
