import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCgAxp_rxEaraCW9oJvn3-QP3OWCp-fJwQ',
  authDomain: 'tarjem-213d9.firebaseapp.com',
  projectId: 'tarjem-213d9',
  storageBucket: 'tarjem-213d9.appspot.com',
  messagingSenderId: '315711957724',
  appId: '1:315711957724:web:f01fe84028f6cb4c6f6219',
  measurementId: 'G-0JPK3R3SJP',
};

export const app = firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === 'localhost') {
//   console.log(
//     'testing locally -- hitting local functions and firestore emulators'
//   );
//   firebase.firestore().useEmulator('localhost', 8080);
//   firebase.functions().useEmulator('localhost', 5001);
//   firebase.auth().useEmulator('http://localhost:9099/');
// }

export const functions = firebase.functions();
export const db = firebase.firestore();
export const auth = firebase.auth();
