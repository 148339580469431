import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Avatar, TextField, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useStyles } from '../../utils/Style';
import { ArrowBackIos } from '@material-ui/icons';

const UpdateProfile = () => {
  const { updateProfile } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(null);
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username && username !== '') {
      setUsernameError(null);
      await updateProfile(username);
      // history.push('/translate');
    } else {
      setUsernameError('فش إسم');
    }
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <PersonIcon />
      </Avatar>
      {/* <Typography component='h1' variant='h5'>
        مين انت؟
      </Typography> */}
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          id='username'
          label='شو إسمك؟'
          name='username'
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
          autoComplete='off'
          error={usernameError}
          helperText={usernameError}
        ></TextField>
        <Button
          type='submit'
          fullWidth
          variant='outlined'
          color='primary'
          className={classes.submit}
          endIcon={<ArrowBackIos />}
        >
          دخول
        </Button>
      </form>
    </div>
  );
};

export default UpdateProfile;
